import React from 'react'
import { graphql } from 'gatsby'
import useSiteTitle from '../hooks/useSiteTitle'
import { PostList } from '../components/posts'
import { Pagination } from '../components/content'
import { MetaTagsMustHave, createPaginatedPageTitle, createPaginatedMetaProps } from '../components/seo'
import { PageLayoutDefault } from '../components/layout'
import useSiteDescription from '../hooks/useSiteDescription'

const BlogPageTemplate = ({ data, pageContext }) => {
  const { nodes: postsOfPage } = data.wp.postsOfPage
  const siteTitle = useSiteTitle()
  const description = useSiteDescription()

  return (
    <>
      <MetaTagsMustHave
        {...createPaginatedMetaProps(
          pageContext.humanPageNumber,
          {
            description,
            title: `Cikkek | ${siteTitle}`,
          },
        )}
      />
      <PageLayoutDefault
        heading={createPaginatedPageTitle(pageContext.humanPageNumber, 'Cikkek')}
      >
        <PostList posts={postsOfPage} />
        <Pagination {...pageContext} />
      </PageLayoutDefault>
    </>
  )
}

export default BlogPageTemplate

export const pageQuery = graphql`
  query BlogPageQuery($first: Int!, $after: String) {
    wp {
      postsOfPage: posts(
        first: $first
        after: $after
        where: {
          status: PUBLISH
          language: DEFAULT
          orderby: { field: DATE, order: DESC }
        }
      ) {
        nodes {
          ...PostListFields
          ...PostListImageFields
        }
      }
    }
  }
`
